import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WindowService } from '../core/services/window.service';
import { ILocale } from './interfaces/ILocale';
import { LanguageFacade } from './language.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit, OnDestroy {
  public locales: ILocale[] = [];

  public currentRoute = '';

  private localeObserver: Subscription | null = null;

  constructor(
    private languageFacade: LanguageFacade,
    private windowService: WindowService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.locales = this.languageFacade.getAvailableLanguages();

    const urlLocale = this.windowService?.window?.location.pathname
      .toLocaleLowerCase()
      .split('/')[1];

    this.languageFacade.initLocale(urlLocale);
    this.initCurrentRoute();
    this.subscribeLocale();
  }

  public ngOnDestroy(): void {
    this.localeObserver?.unsubscribe();
  }

  public onLanguageUpdate($event: Event, locale: string): void {
    $event.preventDefault();

    this.languageFacade.setCurrentLocale(locale);
  }

  private initCurrentRoute(): void {
    this.currentRoute = this.router.url;
  }

  private subscribeLocale(): void {
    this.localeObserver = this.languageFacade.getCurrentLocale$().subscribe((locale) => {
      if (
        this.windowService.window &&
        !this.windowService.window.location.pathname
          .toLocaleLowerCase()
          .includes(locale.toLowerCase())
      ) {
        if(locale === 'ca') {
          this.windowService.window.location.href = `${window.location.protocol}//${environment.caDomain}${this.router.url}`;
          return;
        }
        this.windowService.window.location.href = `${window.location.protocol}//${window.location.host}/${locale}${this.router.url}`;
      }
    });
  }
}
