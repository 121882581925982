import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILocale } from '../interfaces/ILocale';
import { LOCALES } from '../utils/language.config';
import { WindowService } from '../../core/services/window.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageState {

  private availableLanguages = LOCALES;

  private currentLocale$ = new BehaviorSubject<string>('');

  constructor(
    private windowService: WindowService,
  ) {}

  public initLocale(language?: string): void {
    const locale = language ?? 'en';

    this.setCurrentLocale(locale);
  }

  public getCurrentLocale(): string {
    const urlLocale = this.windowService?.window?.location.pathname
    .toLocaleLowerCase()
    .split('/')[1];
    return urlLocale ?? 'en';
  }

  public getCurrentLocale$(): Observable<string> {
    return this.currentLocale$.asObservable();
  }

  public setCurrentLocale(locale: string): void {
    this.currentLocale$.next(locale);
  }

  public getAvailableLanguages(): ILocale[] {
    return this.availableLanguages;
  }
}
