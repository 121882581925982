export const howItWorksTexts = [
  $localize`Create an account by entering your basic details`,
  $localize`Upload, edit and sort your data into our neat categories`,
  $localize`Assign Trusted Appointees – the people you want to nominate to take responsibility when the time comes`,
  $localize`Effortlessly manage your legacy, assets and memories`,
];

export const howItWorksTextsFormal = [
  $localize`Create an account by entering your basic details`,
  $localize`Upload, edit and sort your data into our neat categories`,
  $localize`Assign Trusted Appointees – the people you want to nominate to take responsibility when the time comes`,
  $localize`Effortlessly manage your legacy, assets and memories`,
];

export const howItWorksTextsInformal = [
  $localize`Create an account by entering your basic details.`,
  $localize`Upload, edit and sort your data into our neat categories.`,
  $localize`Assign Trusted Appointees – the people you want to nominate to take responsibility when the time comes.`,
  $localize`Effortlessly manage your legacy, assets and memories.`,
];

export const howItWorksTextsDhfm = [
  $localize`Create an account by following a few simple steps.`,
  $localize`Upload, organise, and categorise all your documentation into different folders.`,
  $localize`Select one or more trusted appointees per folder who will have access after your passing.`,
  $localize`Modify the contents of folders and add necessary documents.`,
];

export const howItWorksTextsAdvies = [
  'Je maakt een account aan door een aantal simpele stappen te doorlopen.',
  'Upload, sorteer en verdeel al je documentatie in verschillende mappen.',
  'Wijs de mensen aan waarvan je wilt dat ze te zijner tijd de verantwoordelijkheid op zich nemen.',
  'Wijzig de inhoud van mappen en voeg de nodige documenten toe.',
];