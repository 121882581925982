export const howItWorksTexts = [
  $localize`Organise all your important information in one place.`,
  $localize`Customise information easily, at any time.`,
  $localize`Share information securely with your designated loved ones.`,
  $localize`You decide who can view which information.`,
  $localize`Your loved ones know your wishes and can follow them.`,
  $localize`Time to live in the now because later is arranged.`,
];

export const howItWorksTextsIfa = [
  $localize`1. Become a partner`,
  $localize`2. Receive content for communication outlets, such as input for newsletters and social media`,
  $localize`3. Recommend Life After Me to your clients`,
  $localize`4. Your clients are granted access to their personal dashboard to safely store their information`,
  $localize`5. Your clients have full access to our customer support`,
];

export const howItWorksTextsFormal = [
  `Organiseer al uw belangrijke informatie op één plek, eventueel met onze hulp`,
  `Deel informatie veilig met door u aangewezen dierbaren`,
  `U bepaalt wie welke informatie kan bekijken`,
  `Uw dierbaren kennen uw wensen en kunnen die opvolgen`,
  `Pas informatie eenvoudig, op ieder moment aan`,
  `Tijd om in het nu te leven, omdat later is geregeld`,
];

export const howItWorksTextsInformal = [
  `Organiseer al je belangrijke informatie op één plek, eventueel met onze hulp`,
  `Deel informatie veilig met door jou aangewezen dierbaren`,
  `Jij bepaalt wie welke informatie kan bekijken`,
  `Je dierbaren kennen je wensen en kunnen die opvolgen`,
  `Pas informatie eenvoudig, op ieder moment aan`,
  `Tijd om in het nu te leven, omdat later is geregeld`,
];

export const howItWorksTextsSoteria = [
  `Organise all your information in one place`,
  `Share information securely with the loved ones you designate`,
  `You decide which information is shared and with whom`,
  `Your loved ones will know your wishes and can follow them`,
  `You can change information easily, and at any time`,
  `You enjoy the now because later is taken care of`,
];
export const howItWorksTextsDhfm = [
  $localize`Organise all your important information in one place`,
  $localize`Safely share information with designated loved ones`,
  $localize`You determine who can access which information`,
  $localize`Your loved ones understand your wishes and can act accordingly`,
  $localize`Easily update information at any time`,
  $localize`Time to live in the present, because the future is taken care of`,
];

export const howItWorksTextsEnvida = [
  `Organiseer al uw belangrijke informatie op één plek`,
  `Deel informatie veilig met door u aangewezen dierbaren`,
  `U bepaalt wie welke informatie kan bekijken`,
  `Uw dierbaren kennen uw wensen en kunnen die opvolgen`,
  `Pas informatie eenvoudig en op ieder moment aan`,
  `Tijd om in het nu te leven omdat later is geregeld`,
];

export const howItWorksTextsHorizons = [
  `Your clients can organise all their information in one place`,
  `They can safely share information with designated loved ones`,
  `They determine who has access to which information and when`,
  `Your clients loved ones will be able to understand their wishes and act accordingly`,
  `They can easily update their information at any time`,
  `Give your clients the freedom to enjoy the present, because the future is taken care of`,
];