import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { WindowService } from 'src/app/features/core/services/window.service';
import { LanguageFacade } from 'src/app/features/language/language.facade';


@Component({
  selector: 'app-front-pages-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  @Input() public isMobileView: boolean = true;

  @Input() public appUri = '';

  @Output() public closeMenu = new EventEmitter();

  isShowThuiswinkel = false;
  isCaVersion = false;
  
  constructor(
    private languageFacade: LanguageFacade,
    private windowService: WindowService,
  ) {}

   public ngOnInit(): void {
    if(this.languageFacade.getCurrentLocale() === 'nl') {
      this.isShowThuiswinkel = true;
    }
    this.isCaVersion = this.windowService.caVersion;
  }
  

  public onCloseMenu(): void {
    this.closeMenu.emit();
  }
  

}
