import { Injectable } from '@angular/core';
import { LanguageFacade } from '../../language/language.facade';
import { WindowService } from '../../core/services/window.service';
import { Apollo } from 'apollo-angular';
import { IGetPricesQuery } from '../interfaces/GQL';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client';
import getPricesQuery from '../gql/get-prices.gql';
@Injectable()
export class PlansService {
  
  constructor(
    private languageFacade: LanguageFacade,
    private windowService: WindowService,
    private apollo: Apollo,
  ) {}

  public getAvailablePlans(): Observable<FetchResult<IGetPricesQuery>> {
    let lang = this.languageFacade.getCurrentLocale();
    //check if the is Ca version
    if (this.windowService.caVersion) lang = 'ca';

    return this.apollo.query<IGetPricesQuery>({
      query: getPricesQuery,
      variables: {
        lang,
      },
      errorPolicy: 'all',
    });
  }
}
