import { LinkType, MenuItem } from '../interfaces/MenuItem';

export const MAIN_MENU_ITEMS: MenuItem[] = [
  {
    routerLink: '/why-life-after-me',
    text: $localize`Why Life After Me?`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/how-it-works',
    text: $localize`How does it work?`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/security',
    text: $localize`Security`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/contact',
    text: $localize`Contact`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/faq',
    text: $localize`FAQ`,
    type: LinkType.INTERNAL,
  },
];
export const SECONDARY_MENU_ITEMS: MenuItem[] = [
  {
    routerLink: '/about',
    text: $localize`About us`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: 'https://www1.plant-for-the-planet.org/',
    text: $localize`Plant for the Planet`,
    type: LinkType.EXTERNAL,
  },
  {
    routerLink: '/privacy-policy',
    text: $localize`Privacy`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/terms-and-conditions',
    text: $localize`Terms & Conditions`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/complaints',
    text: $localize`Complaints`,
    type: LinkType.INTERNAL,
  },
  {
    routerLink: '/herroeping',
    text: 'Herroepping',
    type: LinkType.INTERNAL,
  },
];
