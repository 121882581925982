import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class WindowService {
  private isProVersion: boolean = false;

  private isCaVersion: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  public get window(): Window | null {
    if (!isPlatformBrowser(this.platformId)) return null;

    return window;
  }

  public checkProVersion(): void {
    this.isProVersion = this.window?.location.origin.includes(environment.proDomain) || false;
  }

  public checkCaVersion(): void {
    this.isCaVersion = this.window?.location.origin.includes(environment.caDomain) || false;
  }

  public get proVersion(): boolean {
    return this.isProVersion;
  }

  public get caVersion(): boolean {
    return this.isCaVersion;
  }
}
