import { NgModule } from '@angular/core';
import { DocumentService } from './services/document.service';
import { LocalStorageService } from './services/local-storage.service';
import { ScriptService } from './services/script.service';
import { WindowService } from './services/window.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [LocalStorageService, WindowService, DocumentService, ScriptService],
})
export class CoreModule {}
