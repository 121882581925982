import { Injectable } from '@angular/core';
import { IFAQ } from '../interfaces/IFaq';
import { caFAQS, proFAQS } from '../utils/faqs';
import { WindowService } from '../../core/services/window.service';

@Injectable()
export class FaqService {

  constructor(private windowService: WindowService) {}
  
  public getFaqs(): IFAQ[] {
    if(this.windowService.caVersion) return caFAQS;
    return proFAQS;
  }
}
